






import Vue from 'vue';

export default Vue.extend({
  name: 'DataTableItemSeconds',

  props: {
    value: {
      type: Number,
      default: undefined,
    },
  },

  computed: {
    seconds(): string {
      if (this._.isNil(this.value)) {
        return '---';
      }

      const hours = Math.floor(this.value / 3600);
      const minutes = Math.floor((this.value - hours * 3600) / 60);
      const seconds = this.value - hours * 3600 - minutes * 60;
      const time = [];

      if (hours > 0) {
        time.push(`${hours}時間`);
      }
      if (minutes > 0) {
        time.push(`${minutes}分`);
      }
      if (seconds > 0) {
        time.push(`${seconds}秒`);
      }

      if (this.value >= 60) {
        return `${time.join('')} (${this.value.toLocaleString()}秒)`;
      }

      return `${this.value}秒`;
    },
  },
});
