






import Vue from 'vue';

export default Vue.extend({
  name: 'DataTableItemDateTime',

  props: {
    value: {
      type: String,
      default: undefined,
    },
  },

  computed: {
    dateTime(): string {
      if (this._.isNil(this.value) || this.value === '') {
        return '---';
      }

      return this.$$dayjsParseAndFormatToDateTimeString(this.value);
    },
  },
});
