

















import Vue from 'vue';
import { DIAL_STATS } from '@/resources/defines';

export default Vue.extend({
  name: 'DataTableItemDialStat',

  props: {
    value: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      dialStats: DIAL_STATS,
    };
  },
});
