























































import { mdiMenuDown } from '@mdi/js';
import Vue from 'vue';
import DateTimePicker from '@/components/generic/DateTimePicker.vue';

type PresetRangeType =
  | 'today'
  | 'yesterday'
  | 'beforeYesterday'
  | 'thisWeek'
  | 'lastWeek'
  | 'thisMonth'
  | 'lastMonth'
  | 'clear';

const presetRanges: { name: string; type: PresetRangeType }[] = [
  {
    name: '今日',
    type: 'today',
  },
  {
    name: '昨日',
    type: 'yesterday',
  },
  {
    name: '一昨日',
    type: 'beforeYesterday',
  },
  {
    name: '今週',
    type: 'thisWeek',
  },
  {
    name: '先週',
    type: 'lastWeek',
  },
  {
    name: '今月',
    type: 'thisMonth',
  },
  {
    name: '先月',
    type: 'lastMonth',
  },
  {
    name: 'クリア',
    type: 'clear',
  },
];

export default Vue.extend({
  name: 'DateTimeRange',

  components: {
    DateTimePicker,
  },

  props: {
    // メニュー外部クリックで閉じる際にキャンセル扱いとするかどうか
    cancelOnClickOutside: {
      type: Boolean,
    },
    // デートピッカーのプロパティ
    // TODO: 個別指定のプロパティを含めないようにバリデート
    datePickerProps: {
      type: Object,
      default() {
        return {};
      },
    },
    // 日時表示のフォーマット
    dateTimeFormat: {
      type: String,
      default(): string {
        return this.$$dayjsFormats.displayFormatDateTimeDefault;
      },
    },
    // 無効化するかどうか
    disabled: {
      type: Boolean,
    },
    // エラーとするかどうか
    error: {
      type: Boolean,
    },
    // 一度に表示するエラーの数
    errorCount: {
      type: [Number, String],
      default: 1,
    },
    // カスタムエラーメッセージ
    errorMessages: {
      type: [String, Array],
      default() {
        return [];
      },
    },
    // 開始日時のラベル
    fromLabel: {
      type: String,
      default: '開始',
    },
    // メニューのプロパティ
    // TODO: 個別指定のプロパティを含めないようにバリデート
    menuProps: {
      type: Object,
      default() {
        return {};
      },
    },
    // テキストフィールドのプロパティ
    // TODO: 個別指定のプロパティを含めないようにバリデート
    textFieldProps: {
      type: Object,
      default() {
        return {};
      },
    },
    // タイムピッカーのプロパティ
    // TODO: 個別指定のプロパティを含めないようにバリデート
    timePickerProps: {
      type: Object,
      default: undefined,
    },
    // 終了日時のラベル
    toLabel: {
      type: String,
      default: '終了',
    },
    // 開始日時と終了日時 (初期値及び親からの変更通知用)
    value: {
      type: Object,
      default() {
        return {
          from: undefined,
          to: undefined,
        };
      },
    },
  },

  data(): {
    fromInComponent: string | undefined;
    icons: {
      [key: string]: string;
    };
    presetRanges: {
      name: string;
      type: PresetRangeType;
    }[];
    toInComponent: string | undefined;
  } {
    return {
      // 開始日時 (コンポーネント内の制御用)
      fromInComponent: undefined,
      // アイコン
      icons: {
        mdiMenuDown,
      },
      presetRanges,
      // 終了日時 (コンポーネント内の制御用)
      toInComponent: undefined,
    };
  },

  watch: {
    // 開始日時が変更された場合、変更を親に通知する
    fromInComponent: {
      handler(value) {
        this.$emit('update:value', { from: value, to: this.toInComponent });
      },
    },

    // 終了日時が変更された場合、変更を親に通知する
    toInComponent: {
      handler(value) {
        this.$emit('update:value', { from: this.fromInComponent, to: value });
      },
    },

    // 開始日時と終了日時が親で変更された場合、それをコンポーネント内に反映する
    value: {
      immediate: true,
      handler(value) {
        this.fromInComponent = value.from;
        this.toInComponent = value.to;
      },
    },
  },

  methods: {
    setDateRange(type: PresetRangeType) {
      let from: string | undefined;
      let to: string | undefined;

      if (type === 'today') {
        const startOfToday = this.$$dayjs().startOf('day');
        const startOfTomorrow = startOfToday.clone().add(1, 'day');

        from = this.$$dayjsStringify(startOfToday);
        to = this.$$dayjsStringify(startOfTomorrow);
      } else if (type === 'yesterday') {
        const startOfToday = this.$$dayjs().startOf('day');
        const startOfYesterday = startOfToday.clone().subtract(1, 'day');

        from = this.$$dayjsStringify(startOfYesterday);
        to = this.$$dayjsStringify(startOfToday);
      } else if (type === 'beforeYesterday') {
        const startOfYesterday = this.$$dayjs().startOf('day').subtract(1, 'day');
        const startOfBeforeYesterday = startOfYesterday.clone().subtract(1, 'day');

        from = this.$$dayjsStringify(startOfBeforeYesterday);
        to = this.$$dayjsStringify(startOfYesterday);
      } else if (type === 'thisWeek') {
        const startOfThisWeek = this.$$dayjs().startOf('week');
        const startOfNextWeek = startOfThisWeek.clone().add(1, 'week');

        from = this.$$dayjsStringify(startOfThisWeek);
        to = this.$$dayjsStringify(startOfNextWeek);
      } else if (type === 'lastWeek') {
        const startOfThisWeek = this.$$dayjs().startOf('week');
        const startOfLastWeek = startOfThisWeek.clone().subtract(1, 'week');

        from = this.$$dayjsStringify(startOfLastWeek);
        to = this.$$dayjsStringify(startOfThisWeek);
      } else if (type === 'thisMonth') {
        const startOfThisMonth = this.$$dayjs().startOf('month');
        const startOfNextMonth = startOfThisMonth.clone().add(1, 'month');

        from = this.$$dayjsStringify(startOfThisMonth);
        to = this.$$dayjsStringify(startOfNextMonth);
      } else if (type === 'lastMonth') {
        const startOfThisMonth = this.$$dayjs().startOf('month');
        const startOfLastMonth = startOfThisMonth.clone().subtract(1, 'month');

        from = this.$$dayjsStringify(startOfLastMonth);
        to = this.$$dayjsStringify(startOfThisMonth);
      } else {
        from = undefined;
        to = undefined;
      }

      this.$emit('update:value', {
        from,
        to,
      });
    },
  },
});
