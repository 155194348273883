










import Vue from 'vue';
import { TRIGGERS } from '@/resources/defines';

export default Vue.extend({
  name: 'DataTableItemTrigger',

  props: {
    value: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      triggers: TRIGGERS,
    };
  },
});
