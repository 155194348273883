










import Vue from 'vue';
import { FLOW_TYPES } from '@/resources/defines';

export default Vue.extend({
  name: 'DataTableItemFlowType',

  props: {
    value: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      flowTypes: FLOW_TYPES,
    };
  },
});
