






import Vue from 'vue';

export default Vue.extend({
  name: 'DataTableItemKeyValue',

  props: {
    label: {
      type: String,
      default: undefined,
    },
    value: {
      // 通話ログの制限により、オブジェクトの中身が空の場合に空配列として記録されるためArrayを許容する
      type: [Object, Array],
      default: undefined,
    },
  },

  computed: {
    keyValue(): string {
      if (this._.isArray(this.value)) {
        return '';
      }

      const keyValueArray = this._.map(this.value, (value, key) => {
        return `${key}=${value}`;
      });

      return keyValueArray.sort().join(', ');
    },
  },
});
