
















import Vue from 'vue';
import { NOTIFY_STATUSES } from '@/resources/defines';

export default Vue.extend({
  name: 'DataTableItemNotifyStatus',

  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      notifyStatuses: NOTIFY_STATUSES,
    };
  },
});
