
import Vue, { VueConstructor, PropType } from 'vue';
import { Bar, mixins } from 'vue-chartjs';
import type Chart from 'chart.js';
import type { ReactivePropMixin } from 'vue-chartjs/types/mixins.d';

const { reactiveProp } = mixins;

export default (Vue as VueConstructor<Vue & Bar & ReactivePropMixin>).extend({
  name: 'BarChart',
  extends: Bar,
  mixins: [reactiveProp],
  props: {
    options: {
      type: Object as PropType<Chart.ChartOptions>,
      default: undefined,
    },
  },
  mounted() {
    this.renderChart(this.chartData, this.options);
  },
});
