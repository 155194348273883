



























































import { mdiCalendar, mdiClockOutline } from '@mdi/js';
import Vue from 'vue';
import type { Dayjs } from 'dayjs';

// 内部で使用している各コンポーネントのプロパティの初期値
const defaultValues = {
  datePickerProps: {
    headerColor: 'accent',
    scrollable: true,
  },
  menuProps: {
    maxWidth: '290px',
    minWidth: '290px',
    offsetY: true,
    transition: 'scale-transition',
  },
  textFieldProps: {
    clearable: true,
    hideDetails: true,
    outlined: true,
  },
  timePickerProps: {
    format: '24hr',
    headerColor: 'accent',
    scrollable: true,
    useSeconds: true,
  },
};

export default Vue.extend({
  name: 'DateTimePicker',

  props: {
    // メニュー外部クリックで閉じる際にキャンセル扱いとするかどうか
    cancelOnClickOutside: {
      type: Boolean,
    },
    // デートピッカーのプロパティ
    // TODO: 個別指定のプロパティを含めないようにバリデート
    datePickerProps: {
      type: Object,
      default() {
        return {};
      },
    },
    // 日時表示のフォーマット
    dateTimeFormat: {
      type: String,
      default(): string {
        return this.$$dayjsFormats.displayFormatDateTimeDefault;
      },
    },
    // 無効化するかどうか
    disabled: {
      type: Boolean,
    },
    // エラーとするかどうか (初期値及び親からの変更通知用)
    error: {
      type: Boolean,
    },
    // ラベル
    label: {
      type: String,
      default: undefined,
    },
    // メニューのプロパティ
    // TODO: 個別指定のプロパティを含めないようにバリデート
    menuProps: {
      type: Object,
      default() {
        return {};
      },
    },
    // テキストフィールドのプロパティ
    // TODO: 個別指定のプロパティを含めないようにバリデート
    textFieldProps: {
      type: Object,
      default() {
        return {};
      },
    },
    // タイムピッカーのプロパティ
    // TODO: 個別指定のプロパティを含めないようにバリデート
    timePickerProps: {
      type: Object,
      default: undefined,
    },
    // 編集対象の日時文字列 (初期値及び親からの変更通知用)
    value: {
      type: String,
      default: undefined,
    },
  },

  data(): {
    activeTab: 'date' | 'time';
    date: string;
    errorInComponent: boolean;
    icons: {
      [key: string]: string;
    };
    showedMenu: boolean;
    time: string;
    valueInComponent: string | undefined;
  } {
    return {
      // 現在選択中のタブ
      activeTab: 'date',

      // 日付文字列 (ピッカーで編集中の値保持用)
      date: '',

      // エラーとするかどうか (コンポーネント内の制御用)
      errorInComponent: false,

      // アイコン
      icons: {
        mdiCalendar,
        mdiClockOutline,
      },

      // メニューを表示しているかどうか
      showedMenu: false,

      // 時間文字列 (ピッカーで編集中の値保持用)
      time: '00:00:00',

      // 編集対象の日時文字列 (コンポーネント内の制御用)
      valueInComponent: undefined,
    };
  },

  computed: {
    // 日時データ (編集中の値の整形処理のための中間データ)
    dateTime(): Dayjs | undefined {
      if (this.date.length > 0 && this.time.length > 0) {
        let dateTime = `${this.date} ${this.time}`;

        if (this.time.length === 5) {
          dateTime += ':00';
        }

        return this.$$dayjs(dateTime, this.$$dayjsFormats.dateTimePickerFormatDefault);
      }

      return undefined;
    },

    // タイムピッカーが有効かどうか
    enableTimePicker(): boolean {
      return this.date.length > 0;
    },

    // 表示用に整形した日時文字列
    formattedDateTimeString(): string {
      if (this.dateTime === undefined) {
        return '';
      }

      return this.dateTime.format(this.dateTimeFormat);
    },

    // デートピッカーのプロパティ (デフォルト値とマージ済み)
    mergedDatePickerProps(): {
      [key: string]: string;
    } {
      return this._.assignIn(defaultValues.datePickerProps, this.datePickerProps);
    },

    // メニューのプロパティ (デフォルト値とマージ済み)
    mergedMenuProps(): {
      [key: string]: string;
    } {
      return this._.assignIn(defaultValues.menuProps, this.menuProps);
    },

    // テキストフィールドのプロパティ (デフォルト値とマージ済み)
    mergedTextFieldProps(): {
      [key: string]: string;
    } {
      return this._.assignIn(defaultValues.textFieldProps, this.textFieldProps);
    },

    // タイムピッカーのプロパティ (デフォルト値とマージ済み)
    mergedTimePickerProps(): {
      [key: string]: string;
    } {
      return this._.assignIn(defaultValues.timePickerProps, this.timePickerProps);
    },
  },

  watch: {
    // エラーとするかどうかが親で変更された場合、それをコンポーネント内に反映する
    error: {
      immediate: true,
      handler(value) {
        this.errorInComponent = value;
      },
    },

    // 編集対象の日時文字列が親で変更された場合、それをコンポーネント内に反映する
    value: {
      immediate: true,
      handler(value) {
        if (value !== this.valueInComponent) {
          this.valueInComponent = value;

          if (this._.isEmpty(value)) {
            this.date = '';
            this.time = '00:00:00';
          } else {
            const dayjs = this.$$dayjsParse(value);

            this.date = dayjs.format(this.$$dayjsFormats.datePickerFormatDefault);
            this.time = dayjs.format(this.$$dayjsFormats.timePickerFormatDefault);
          }
        }
      },
    },
  },

  methods: {
    // 編集中の値を破棄し、編集前の値に戻す
    cancel() {
      if (this.valueInComponent === undefined) {
        this.date = '';
        this.time = '00:00:00';
      } else {
        const dayjs = this.$$dayjsParse(this.valueInComponent);

        this.date = dayjs.format(this.$$dayjsFormats.datePickerFormatDefault);
        this.time = dayjs.format(this.$$dayjsFormats.timePickerFormatDefault);
      }
    },

    // キャンセルボタンがクリックされた場合の処理
    cancelHandler() {
      this.showedMenu = false;
      this.activeTab = 'date';

      this.cancel();
    },

    // テキストフィールドのクリアアイコンがクリックされた場合の処理
    // 編集前/中の値を破棄して初期値に戻し、初期値への変更を親に通知する
    clickClearHandler() {
      this.valueInComponent = undefined;
      this.date = '';
      this.time = '00:00:00';
      this.$emit('update:value', this.valueInComponent);
    },

    // メニューの外部がクリックされた場合の処理
    clickOutsideHandler() {
      this.activeTab = 'date';

      if (this.cancelOnClickOutside) {
        this.cancel();
      } else {
        this.save();
      }
    },

    // 確定/閉じるボタンがクリックされた場合の処理
    okHandler() {
      this.showedMenu = false;
      this.activeTab = 'date';

      this.save();
    },

    // 編集中の値を確定値としてコンポーネントに保持し、確定値への変更を親に通知する
    save() {
      if (this.dateTime === undefined) {
        this.valueInComponent = undefined;
      } else {
        this.valueInComponent = this.$$dayjsStringify(this.dateTime);
      }

      this.$emit('update:value', this.valueInComponent);
    },

    // タイムピッカーを表示する
    showTimePicker() {
      this.activeTab = 'time';
    },

    // エラーとするかどうかが更新された場合の処理
    updateErrorHandler(error: boolean) {
      this.errorInComponent = error;
      this.$emit('update:error', error);
    },
  },
});
