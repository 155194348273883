
















import Vue from 'vue';
import { INTERFACE_PROGRESSES } from '@/resources/defines';

export default Vue.extend({
  name: 'DataTableItemInterfaceProgress',

  props: {
    value: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      interfaceProgresses: INTERFACE_PROGRESSES,
    };
  },
});
