










import Vue from 'vue';
import { FLOW_BLOCK_TYPES } from '@/resources/defines';

export default Vue.extend({
  name: 'DataTableItemFlowBlockType',

  props: {
    value: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      flowBlockTypes: FLOW_BLOCK_TYPES,
    };
  },
});
