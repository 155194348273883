










import Vue from 'vue';
import { HUP_USRS } from '@/resources/defines';

export default Vue.extend({
  name: 'DataTableItemHupUsr',

  props: {
    value: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      hupUsrs: HUP_USRS,
    };
  },
});
