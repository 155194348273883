






import Vue from 'vue';

export default Vue.extend({
  name: 'DataTableItemFormattedNumber',

  props: {
    defaultValue: {
      type: String,
      default: '---',
    },

    value: {
      type: Number,
      default: undefined,
    },
  },

  computed: {
    formattedNumber(): string {
      if (this._.isNil(this.value)) {
        return this.defaultValue;
      }

      return this.value.toLocaleString();
    },
  },
});
