










































































































































































































































































































































































































































































































































































import { mdiMenuDown, mdiTableSearch, mdiTuneVariant, mdiOpenInApp } from '@mdi/js';
import { DebouncedFunc } from 'lodash';
import Vue from 'vue';
import { required, helpers, maxLength } from 'vuelidate/lib/validators';
import BarChart from '@/components/generic/BarChart.vue';
import CheckboxGroup from '@/components/generic/CheckboxGroup.vue';
import DataTable from '@/components/generic/DataTable.vue';
import DataTableItemCallType from '@/components/generic/DataTableItemCallType.vue';
import DataTableItemDateTime from '@/components/generic/DataTableItemDateTime.vue';
import DataTableItemDialStat from '@/components/generic/DataTableItemDialStat.vue';
import DataTableItemFlowBlockType from '@/components/generic/DataTableItemFlowBlockType.vue';
import DataTableItemFlowType from '@/components/generic/DataTableItemFlowType.vue';
import DataTableItemFormattedNumber from '@/components/generic/DataTableItemFormattedNumber.vue';
import DataTableItemHupUsr from '@/components/generic/DataTableItemHupUsr.vue';
import DataTableItemInterfaceProgress from '@/components/generic/DataTableItemInterfaceProgress.vue';
import DataTableItemKeyValue from '@/components/generic/DataTableItemKeyValue.vue';
import DataTableItemNotifyStatus from '@/components/generic/DataTableItemNotifyStatus.vue';
import DataTableItemSeconds from '@/components/generic/DataTableItemSeconds.vue';
import DataTableItemTrigger from '@/components/generic/DataTableItemTrigger.vue';
import DataTableItemWrap from '@/components/generic/DataTableItemWrap.vue';
import DateTimeRange from '@/components/generic/DateTimeRange.vue';
import PageTitle from '@/components/specific/PageTitle.vue';
import {
  DIAL_STATS,
  NOTIFY_PROGRESSES,
  INTERFACE_PROGRESSES,
  HTTP_STATUSES,
} from '@/resources/defines';
import wait from '@/resources/functions/wait';
import ServiceFactory from '@/services/ui/ServiceFactory';
import { DomainPartnerMapper } from '@/store/modules/domain/partner';
import { UICommonMapper } from '@/store/modules/ui/common';
import type Chart from 'chart.js';
import type dayjs from 'dayjs';
import type { DataTableHeader, DataOptions } from 'vuetify';

type LogType = 'callLogs' | 'requestLogs';
type LogTypeNames = '発報単位' | 'リクエスト単位';

type aggregationsBucketCallLogs = {
  by_dial_stat: {
    buckets: {
      doc_count: number;
      key: string;
    }[];
    doc_count_error_upper_bound: number;
    sum_other_doc_count: number;
  };
  doc_count: number;
  key: number;
  key_as_string: string;
};

type aggregationsBucketRequestLogs = {
  by_http_status: {
    buckets: {
      doc_count: number;
      key: string;
    }[];
    doc_count_error_upper_bound: number;
    sum_other_doc_count: number;
  };
  doc_count: number;
  key: number;
  key_as_string: string;
};

const AuthService = ServiceFactory.get('auth');
const NotificationLogService = ServiceFactory.get('notificationLog');

const displayableMaxDaysInHourlyChart = 2;
const displayableMaxDaysInDailyChart = 31;
const displayableMaxMonthsInMonthlyChart = 24;

const intervals = [
  {
    name: '年',
    value: 'year',
  },
  {
    name: '月',
    value: 'month',
  },
  {
    name: '日',
    value: 'day',
  },
  {
    name: '時間',
    value: 'hour',
  },
];

const staticSearchParams = {
  callLogs: {
    join: {
      sources: ['progress', 'trn_id'],
      types: ['http'],
    },
    sources: [
      'proc_trn_id',
      'trn_id',
      'flow_id',
      'flow_name',
      'fwd_id',
      'cst_tms',
      'calling_sec',
      'talking_sec',
      'dial_stat',
      'trigger',
      'ans_tms',
      'hup_tms',
      'hup_usr',
      'call_type',
      'call_rate',
      'flow_vars',
      'flow_result',
    ],
    type: 'calllog',
  },
  requestLogs: {
    sources: [
      'proc_trn_id',
      'trn_id',
      'flow_id',
      'flow_name',
      'trn_tms',
      'trigger',
      'progress',
      'http_status',
      'flow_vars',
      'req_method',
      'req_url',
      'req_query',
      'req_headers',
      'req_body',
      'http_response',
    ],
    type: 'ifdial',
  },
};

const chartStyles = {
  height: '330px',
  position: 'relative',
  width: '100%',
};

// 集計パラメーターを生成する
function generateAggregateParams(
  aggregationField: string,
  aggregationSize: number,
  dateHistogramField: string,
  dateHistogramIntervals: string[]
) {
  const aggregateParams: {
    [key: string]: {
      aggs: {
        [key: string]: {
          terms: {
            field: string;
            size: number;
          };
        };
      };
      date_histogram: {
        field: string;
        interval: string;
        time_zone: string;
      };
    };
  } = {};

  dateHistogramIntervals.forEach((interval) => {
    aggregateParams[`by_${interval}`] = {
      aggs: {
        [`by_${aggregationField}`]: {
          terms: {
            field: aggregationField,
            size: aggregationSize,
          },
        },
      },
      // ES上のパラメーター名のため除外
      // eslint-disable-next-line @typescript-eslint/camelcase
      date_histogram: {
        field: dateHistogramField,
        interval,
        // ES上のパラメーター名のため除外
        // eslint-disable-next-line @typescript-eslint/camelcase
        time_zone: 'Asia/Tokyo',
      },
    };
  });

  return aggregateParams;
}

export default Vue.extend({
  name: 'NotificationLogsPage',

  components: {
    BarChart,
    CheckboxGroup,
    DataTable,
    DataTableItemCallType,
    DataTableItemDateTime,
    DataTableItemDialStat,
    DataTableItemFlowBlockType,
    DataTableItemFlowType,
    DataTableItemFormattedNumber,
    DataTableItemHupUsr,
    DataTableItemInterfaceProgress,
    DataTableItemKeyValue,
    DataTableItemNotifyStatus,
    DataTableItemSeconds,
    DataTableItemTrigger,
    DataTableItemWrap,
    DateTimeRange,
    PageTitle,
  },

  props: {
    icon: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },

  data(): {
    callLogsChart: {
      aggregations: {
        by_day?: {
          buckets: aggregationsBucketCallLogs[] | never[];
        };
        by_hour?: {
          buckets: aggregationsBucketCallLogs[] | never[];
        };
        by_month?: {
          buckets: aggregationsBucketCallLogs[] | never[];
        };
      };
      chartData: Chart.ChartData;
      disabledDaily: boolean;
      disabledHourly: boolean;
      disabledMonthly: boolean;
      interval: string;
      needsDisableDaily: boolean;
      needsDisableHourly: boolean;
      needsDisableMonthly: boolean;
      options: Chart.ChartOptions | undefined;
      selectedDialStats: string[];
      styles: typeof chartStyles;
    };
    callLogsTable: {
      detailsTable: {
        // TODO: 後でちゃんと型定義する
        footerProps: {};
        headers: DataTableHeader[];
        itemKey: string;
        // TODO: ESのデータ形式に合わせて修正
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        items: any[];
        options: DataOptions;
      };
      // TODO: ESのデータ形式に合わせて修正
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      expanded: any[];
      // TODO: 後でちゃんと型定義する
      footerProps: {};
      gotSearchParams: {
        // 内容が不定のため除外
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        filters?: { [key: string]: any }[];
      };
      headers: DataTableHeader[];
      itemKey: string;
      // TODO: ESのデータ形式に合わせて修正
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      items: any[];
      loading: boolean | string;
      options: DataOptions;
      serverItemsLength: number;
    };
    icons: {
      [key: string]: string;
    };
    items: {
      dialStats: typeof DIAL_STATS;
      intervals: typeof intervals;
      logTypeNames: LogTypeNames[];
      logTypes: LogType[];
      progresses: typeof INTERFACE_PROGRESSES;
    };
    loading: {
      notifyResult: boolean;
    };
    requestLogsChart: {
      aggregations: {
        by_day?: {
          buckets: aggregationsBucketRequestLogs[] | never[];
        };
        by_hour?: {
          buckets: aggregationsBucketRequestLogs[] | never[];
        };
        by_month?: {
          buckets: aggregationsBucketRequestLogs[] | never[];
        };
      };
      chartData: Chart.ChartData;
      disabledDaily: boolean;
      disabledHourly: boolean;
      disabledMonthly: boolean;
      interval: string;
      needsDisableDaily: boolean;
      needsDisableHourly: boolean;
      needsDisableMonthly: boolean;
      options: Chart.ChartOptions | undefined;
      selectedProgresses: string[];
      styles: typeof chartStyles;
    };
    requestLogsTable: {
      // TODO: ESのデータ形式に合わせて修正
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      expanded: any[];
      // TODO: 後でちゃんと型定義する
      footerProps: {};
      gotSearchParams: {
        // 内容が不定のため除外
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        filters?: { [key: string]: any }[];
      };
      headers: DataTableHeader[];
      itemKey: string;
      // TODO: ESのデータ形式に合わせて修正
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      items: any[];
      loading: boolean | string;
      options: DataOptions;
      serverItemsLength: number;
    };
    searchParams: {
      callLogs: {
        cst_tms: {
          from: string | undefined;
          to: string | undefined;
        };
        dial_stat: string[];
        free_word: string;
      };
      requestLogs: {
        trn_tms: {
          from: string | undefined;
          to: string | undefined;
        };
        progress: string[];
        free_word: string;
      };
    };
    selectedLogType: LogType;
    showedDialog: {
      [key: string]: boolean;
    };
    webhookDetails: {
      proc_start_tms: string;
      proc_end_tms: string;
      progress: string;
      req_data: string;
      res_result: string;
    };
  } {
    return {
      callLogsChart: {
        aggregations: {},
        chartData: {},
        disabledDaily: false,
        disabledHourly: false,
        disabledMonthly: false,
        interval: 'day',
        needsDisableDaily: false,
        needsDisableHourly: false,
        needsDisableMonthly: false,
        options: {
          legend: {
            labels: {
              boxWidth: 12,
            },
          },
          maintainAspectRatio: false,
          responsive: true,
          scales: {
            xAxes: [
              {
                stacked: true,
              },
            ],
            yAxes: [
              {
                stacked: true,
              },
            ],
          },
        },
        selectedDialStats: [],
        styles: chartStyles,
      },
      callLogsTable: {
        detailsTable: {
          footerProps: {
            itemsPerPageOptions: [10, 25, 50, 100],
            showCurrentPage: true,
            showFirstLastPage: true,
          },
          headers: [
            {
              text: 'フロー種別',
              value: '$$$flowType',
            },
            {
              text: 'ブロックID',
              value: 'id',
            },
            {
              text: 'ブロック名',
              value: 'name',
            },
            {
              text: 'ブロック種別',
              value: 'type',
            },
            {
              text: '動作結果',
              value: 'result.result',
            },
          ],
          itemKey: 'proc_trn_id',
          items: [],
          options: {
            groupBy: [],
            groupDesc: [],
            itemsPerPage: 25,
            multiSort: false,
            mustSort: true,
            page: 1,
            sortBy: [],
            sortDesc: [],
          },
        },
        expanded: [],
        footerProps: {
          itemsPerPageOptions: [10, 25, 50, 100],
          showCurrentPage: true,
          showFirstLastPage: true,
        },
        gotSearchParams: {},
        headers: [
          {
            sortable: false,
            text: '処理ID',
            value: 'trn_id',
          },
          {
            sortable: false,
            text: 'フローID',
            value: 'flow_id',
          },
          {
            sortable: false,
            text: 'フロー名',
            value: 'flow_name',
          },
          {
            sortable: false,
            text: '発信先番号',
            value: 'fwd_id',
          },
          {
            text: '発信開始日時',
            value: 'cst_tms',
          },
          {
            sortable: false,
            text: '呼び出し秒数',
            value: 'calling_sec',
          },
          {
            sortable: false,
            text: '通話秒数',
            value: 'talking_sec',
          },
          {
            sortable: false,
            text: '通知状況',
            value: 'dial_stat',
          },
          {
            sortable: false,
            text: 'トリガー',
            value: 'trigger',
          },
          {
            sortable: false,
            text: '連携結果',
            value: '$$$notify_status',
          },
        ],
        itemKey: 'proc_trn_id',
        items: [],
        loading: false,
        options: {
          groupBy: [],
          groupDesc: [],
          itemsPerPage: 25,
          multiSort: false,
          mustSort: true,
          page: 1,
          sortBy: ['cst_tms'],
          sortDesc: [true],
        },
        serverItemsLength: 0,
      },
      icons: {
        mdiMenuDown,
        mdiOpenInApp,
        mdiTableSearch,
        mdiTuneVariant,
      },
      items: {
        dialStats: DIAL_STATS,
        intervals,
        logTypeNames: ['発報単位', 'リクエスト単位'],
        logTypes: ['callLogs', 'requestLogs'],
        progresses: INTERFACE_PROGRESSES,
      },
      loading: {
        notifyResult: false,
      },
      requestLogsChart: {
        aggregations: {},
        chartData: {},
        disabledDaily: false,
        disabledHourly: false,
        disabledMonthly: false,
        interval: 'day',
        needsDisableDaily: false,
        needsDisableHourly: false,
        needsDisableMonthly: false,
        options: {
          legend: {
            labels: {
              boxWidth: 12,
            },
          },
          maintainAspectRatio: false,
          responsive: true,
          scales: {
            xAxes: [
              {
                stacked: true,
              },
            ],
            yAxes: [
              {
                stacked: true,
              },
            ],
          },
        },
        selectedProgresses: [],
        styles: chartStyles,
      },
      requestLogsTable: {
        expanded: [],
        footerProps: {
          itemsPerPageOptions: [10, 25, 50, 100],
          showCurrentPage: true,
          showFirstLastPage: true,
        },
        gotSearchParams: {},
        headers: [
          {
            sortable: false,
            text: '処理ID',
            value: 'trn_id',
          },
          {
            sortable: false,
            text: 'フローID',
            value: 'flow_id',
          },
          {
            sortable: false,
            text: 'フロー名',
            value: 'flow_name',
          },
          {
            text: '処理開始日時',
            value: 'trn_tms',
          },
          {
            sortable: false,
            text: 'トリガー',
            value: 'trigger',
          },
          {
            sortable: false,
            text: '処理状況',
            value: 'progress',
          },
          {
            sortable: false,
            text: 'HTTPステータス',
            value: 'http_status',
          },
          {
            sortable: false,
            text: '電話発報詳細',
            value: '$$$trn_id',
          },
        ],
        itemKey: 'proc_trn_id',
        items: [],
        loading: false,
        options: {
          groupBy: [],
          groupDesc: [],
          itemsPerPage: 25,
          multiSort: false,
          mustSort: true,
          page: 1,
          sortBy: ['trn_tms'],
          sortDesc: [true],
        },
        serverItemsLength: 0,
      },
      // TODO: 後で修正
      // eslint-disable-next-line vue/no-unused-properties
      searchParams: {
        callLogs: {
          // ES上の要素名のため除外 (free_wordは独自要素だが他と合わせる)
          /* eslint-disable @typescript-eslint/camelcase */
          cst_tms: {
            from: undefined,
            to: undefined,
          },
          dial_stat: DIAL_STATS.map((dialStat) => dialStat.value),
          free_word: '',
          /* eslint-enable @typescript-eslint/camelcase */
        },
        requestLogs: {
          // ES上の要素名のため除外 (free_wordは独自要素だが他と合わせる)
          /* eslint-disable @typescript-eslint/camelcase */
          free_word: '',
          progress: INTERFACE_PROGRESSES.map((progress) => progress.value),
          trn_tms: {
            from: undefined,
            to: undefined,
          },
          /* eslint-enable @typescript-eslint/camelcase */
        },
      },
      selectedLogType: 'callLogs',
      showedDialog: {
        webhook: false,
      },
      webhookDetails: {
        // ES上の要素名のため除外
        /* eslint-disable @typescript-eslint/camelcase */
        proc_end_tms: '',
        proc_start_tms: '',
        progress: '',
        req_data: '',
        res_result: '',
        /* eslint-enable @typescript-eslint/camelcase */
      },
    };
  },

  computed: {
    ...DomainPartnerMapper.mapState(['registered']),

    // 通知ログを検索する (発報単位)
    // オプション変更時に検索が複数回実行されないようにするために必要
    debouncedSearchCallLogs(): DebouncedFunc<() => Promise<void>> {
      return this._.debounce(this.searchCallLogs, 100);
    },

    // 通知ログを検索する (リクエスト単位)
    // オプション変更時に検索が複数回実行されないようにするために必要
    debouncedSearchRequestLogs(): DebouncedFunc<() => Promise<void>> {
      return this._.debounce(this.searchRequestLogs, 100);
    },

    // グラフを更新する (発報単位)
    debouncedUpdateChartCallLogs(): DebouncedFunc<() => Promise<void>> {
      return this._.debounce(this.updateChartCallLogs, 100);
    },

    // グラフを更新する (リクエスト単位)
    debouncedUpdateChartRequestLogs(): DebouncedFunc<() => Promise<void>> {
      return this._.debounce(this.updateChartRequestLogs, 100);
    },

    // 集計単位の表示名 (発報単位)
    intervalNameCallLogs(): string {
      const matchedInterval = this._.find(intervals, ['value', this.callLogsChart.interval]);

      if (matchedInterval === undefined) {
        return '';
      }

      return matchedInterval.name;
    },

    // 集計単位の表示名 (リクエスト単位)
    intervalNameRequestLogs(): string {
      const matchedInterval = this._.find(intervals, ['value', this.requestLogsChart.interval]);

      if (matchedInterval === undefined) {
        return '';
      }

      return matchedInterval.name;
    },

    // 通知履歴検索のオフセット (発報単位)
    searchOffsetCallLogs(): number {
      return (this.callLogsTable.options.page - 1) * this.callLogsTable.options.itemsPerPage;
    },

    // 通知履歴検索のオフセット (リクエスト単位)
    searchOffsetRequestLogs(): number {
      return (this.requestLogsTable.options.page - 1) * this.requestLogsTable.options.itemsPerPage;
    },

    // 通知履歴検索のソート条件 (発報単位)
    // TODO: 0指定ではなく、配列全体を処理するようにする
    searchSortCallLogs(): { [key: string]: number } {
      return {
        [this.callLogsTable.options.sortBy[0]]: this.callLogsTable.options.sortDesc[0] ? -1 : 1,
      };
    },

    // 通知履歴検索のソート条件 (リクエスト単位)
    // TODO: 0指定ではなく、配列全体を処理するようにする
    searchSortRequestLogs(): { [key: string]: number } {
      return {
        [this.requestLogsTable.options.sortBy[0]]: this.requestLogsTable.options.sortDesc[0]
          ? -1
          : 1,
      };
    },
  },

  watch: {
    // 集計単位が変更されたらグラフを更新する (発報単位)
    'callLogsChart.interval': {
      handler() {
        this.debouncedUpdateChartCallLogs();
      },
    },

    // 通知履歴テーブルの追加行が開かれたら動作詳細テーブルのデータを生成する (発報単位)
    'callLogsTable.expanded': {
      handler(value) {
        const self = this;

        if (value.length === 0) {
          return;
        }

        const expandedItem = value[0];

        // TODO: 後でちゃんと型定義する
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const detailsTableItems: any[] = [];
        let flowTypes: string[];

        if (expandedItem.dial_stat === 'ANSWER') {
          flowTypes = ['answer', 'answered'];
        } else {
          flowTypes = self._.keys(expandedItem.flow_result);
        }

        flowTypes.forEach((flowType) => {
          const flowResult: {
            id: string;
            name: string;
            type: string;
            tag?: string;
            result: {
              nextkey: string;
              nextid: string;
              result: string | boolean | null;
            };
            start: string;
            end: string;
          }[] = self._.get(expandedItem, ['flow_result', flowType], []);

          flowResult.forEach((blockLog) => {
            detailsTableItems.push({
              $$$flowType: flowType,
              id: blockLog.id,
              name: blockLog.name,
              result: blockLog.result,
              type: blockLog.type,
            });
          });
        });

        self.callLogsTable.detailsTable.items = detailsTableItems;
      },
    },

    // データテーブルのオプション (表示件数、ページ数、ソート条件など) が変更されたら再検索を行う (発報単位)
    'callLogsTable.options': {
      handler() {
        this.debouncedSearchCallLogs();
      },
      deep: true,
    },

    // 集計単位が変更されたらグラフを更新する (リクエスト単位)
    'requestLogsChart.interval': {
      handler() {
        this.debouncedUpdateChartRequestLogs();
      },
    },

    // データテーブルのオプション (表示件数、ページ数、ソート条件など) が変更されたら再検索を行う (リクエスト単位)
    'requestLogsTable.options': {
      handler() {
        this.debouncedSearchRequestLogs();
      },
      deep: true,
    },
  },

  created() {
    const startOfMonth = this.$$dayjsStringify(this.$$dayjs().startOf('month'));

    this.searchParams.callLogs.cst_tms.from = startOfMonth;
    this.searchParams.requestLogs.trn_tms.from = startOfMonth;

    this.searchCallLogsWithNewParams();
    this.searchRequestLogsWithNewParams();
  },

  methods: {
    ...UICommonMapper.mapActions(['setErrorMessage']),

    // 集計単位チップの項目が無効化されているかを返す (発報単位)
    disabledIntervalCallLogs(value: string) {
      if (value === 'month') {
        return this.callLogsChart.disabledMonthly;
      }
      if (value === 'day') {
        return this.callLogsChart.disabledDaily;
      }
      if (value === 'hour') {
        return this.callLogsChart.disabledHourly;
      }
      return false;
    },

    // 集計単位チップの項目が無効化されているかを返す (リクエスト単位)
    disabledIntervalRequestLogs(value: string) {
      if (value === 'month') {
        return this.requestLogsChart.disabledMonthly;
      }
      if (value === 'day') {
        return this.requestLogsChart.disabledDaily;
      }
      if (value === 'hour') {
        return this.requestLogsChart.disabledHourly;
      }
      return false;
    },

    // 検索パラメーターを取得する (発報単位)
    getSearchParamsCallLogs() {
      // 内容が不定のため除外
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const filters: { [key: string]: any }[] = [];

      const params: {
        filters?: typeof filters;
      } = {};

      // 発報日時
      if (
        this.searchParams.callLogs.cst_tms.from !== undefined &&
        this.searchParams.callLogs.cst_tms.to !== undefined
      ) {
        filters.push({
          range: {
            // ES上の要素名のため除外
            // eslint-disable-next-line @typescript-eslint/camelcase
            cst_tms: {
              gte: this.$$dayjsParse(this.searchParams.callLogs.cst_tms.from),
              lt: this.$$dayjsParse(this.searchParams.callLogs.cst_tms.to),
            },
          },
        });
      } else if (
        this.searchParams.callLogs.cst_tms.from !== undefined &&
        this.searchParams.callLogs.cst_tms.to === undefined
      ) {
        filters.push({
          range: {
            // ES上の要素名のため除外
            // eslint-disable-next-line @typescript-eslint/camelcase
            cst_tms: {
              gte: this.$$dayjsParse(this.searchParams.callLogs.cst_tms.from),
            },
          },
        });
      } else if (
        this.searchParams.callLogs.cst_tms.from === undefined &&
        this.searchParams.callLogs.cst_tms.to !== undefined
      ) {
        filters.push({
          range: {
            // ES上の要素名のため除外
            // eslint-disable-next-line @typescript-eslint/camelcase
            cst_tms: {
              lt: this.$$dayjsParse(this.searchParams.callLogs.cst_tms.to),
            },
          },
        });
      }

      // 通知状況
      if (
        this.searchParams.callLogs.dial_stat.length > 0 &&
        this.searchParams.callLogs.dial_stat.length < this.items.dialStats.length
      ) {
        const filterDialStats: {
          bool: {
            should: {
              match: {
                dial_stat: string;
              };
            }[];
          };
        } = { bool: { should: [] } };

        this.searchParams.callLogs.dial_stat.forEach((dialStat) => {
          // ES上の要素名のため除外
          // eslint-disable-next-line @typescript-eslint/camelcase
          filterDialStats.bool.should.push({ match: { dial_stat: dialStat } });
        });

        filters.push(filterDialStats);
      }

      // フリーワード
      if (this.searchParams.callLogs.free_word.length > 0) {
        const targets = ['trn_id', 'flow_id', 'flow_name', 'fwd_id'];

        const filterFreeWord: {
          bool: {
            should: {
              wildcard: {
                [target: string]: {
                  value: string;
                };
              };
            }[];
          };
        } = { bool: { should: [] } };

        targets.forEach((target) => {
          filterFreeWord.bool.should.push({
            wildcard: { [target]: { value: `*${this.searchParams.callLogs.free_word}*` } },
          });
        });

        filters.push(filterFreeWord);
      }

      if (filters.length > 0) {
        params.filters = filters;
      }

      return params;
    },

    // 検索パラメーターを取得する (リクエスト単位)
    getSearchParamsRequestLogs() {
      // 内容が不定のため除外
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const filters: { [key: string]: any }[] = [];

      const params: {
        filters?: typeof filters;
      } = {};

      // 処理開始日時
      if (
        this.searchParams.requestLogs.trn_tms.from !== undefined &&
        this.searchParams.requestLogs.trn_tms.to !== undefined
      ) {
        filters.push({
          range: {
            // ES上の要素名のため除外
            // eslint-disable-next-line @typescript-eslint/camelcase
            trn_tms: {
              gte: this.$$dayjsParse(this.searchParams.requestLogs.trn_tms.from),
              lt: this.$$dayjsParse(this.searchParams.requestLogs.trn_tms.to),
            },
          },
        });
      } else if (
        this.searchParams.requestLogs.trn_tms.from !== undefined &&
        this.searchParams.requestLogs.trn_tms.to === undefined
      ) {
        filters.push({
          range: {
            // ES上の要素名のため除外
            // eslint-disable-next-line @typescript-eslint/camelcase
            trn_tms: {
              gte: this.$$dayjsParse(this.searchParams.requestLogs.trn_tms.from),
            },
          },
        });
      } else if (
        this.searchParams.requestLogs.trn_tms.from === undefined &&
        this.searchParams.requestLogs.trn_tms.to !== undefined
      ) {
        filters.push({
          range: {
            // ES上の要素名のため除外
            // eslint-disable-next-line @typescript-eslint/camelcase
            trn_tms: {
              lt: this.$$dayjsParse(this.searchParams.requestLogs.trn_tms.to),
            },
          },
        });
      }

      // 処理状況
      if (
        this.searchParams.requestLogs.progress.length > 0 &&
        this.searchParams.requestLogs.progress.length < this.items.progresses.length
      ) {
        const filterProgresses: {
          bool: {
            should: {
              match: {
                progress: string;
              };
            }[];
          };
        } = { bool: { should: [] } };

        this.searchParams.requestLogs.progress.forEach((progress) => {
          filterProgresses.bool.should.push({ match: { progress } });
        });

        filters.push(filterProgresses);
      }

      // フリーワード
      if (this.searchParams.requestLogs.free_word.length > 0) {
        const targets = ['trn_id', 'flow_id', 'flow_name'];

        const filterFreeWord: {
          bool: {
            should: {
              wildcard: {
                [target: string]: {
                  value: string;
                };
              };
            }[];
          };
        } = { bool: { should: [] } };

        targets.forEach((target) => {
          filterFreeWord.bool.should.push({
            wildcard: { [target]: { value: `*${this.searchParams.requestLogs.free_word}*` } },
          });
        });

        filters.push(filterFreeWord);
      }

      if (filters.length > 0) {
        params.filters = filters;
      }

      return params;
    },

    // 検索フォームのパラメータをリセットする (発報単位)
    resetCallLogs() {
      this.searchParams.callLogs = {
        // ES上の要素名のため除外 (free_wordは独自要素だが他と合わせる)
        /* eslint-disable @typescript-eslint/camelcase */
        cst_tms: {
          from: this.$$dayjsStringify(this.$$dayjs().startOf('month')),
          to: undefined,
        },
        dial_stat: DIAL_STATS.map((dialStat) => dialStat.value),
        free_word: '',
        /* eslint-enable @typescript-eslint/camelcase */
      };

      this.$v.searchParams.callLogs.$reset();
    },

    // 検索フォームのパラメータをリセットする (リクエスト単位)
    resetRequestLogs() {
      this.searchParams.requestLogs = {
        // ES上の要素名のため除外 (free_wordは独自要素だが他と合わせる)
        /* eslint-disable @typescript-eslint/camelcase */
        free_word: '',
        progress: INTERFACE_PROGRESSES.map((progress) => progress.value),
        trn_tms: {
          from: this.$$dayjsStringify(this.$$dayjs().startOf('month')),
          to: undefined,
        },
        /* eslint-enable @typescript-eslint/camelcase */
      };

      this.$v.searchParams.requestLogs.$reset();
    },

    // 通知ログを検索する (発報単位)
    async searchCallLogs() {
      const self = this;

      const dateHistogramIntervals: string[] = ['year'];

      self.callLogsTable.loading = true;

      if (!self.callLogsChart.needsDisableMonthly) {
        dateHistogramIntervals.push('month');
      }

      if (!self.callLogsChart.needsDisableDaily) {
        dateHistogramIntervals.push('day');
      }

      if (!self.callLogsChart.needsDisableHourly) {
        dateHistogramIntervals.push('hour');
      }

      const searchParams = self._.assignIn(
        {},
        self.callLogsTable.gotSearchParams,
        staticSearchParams.callLogs,
        {
          aggs: generateAggregateParams(
            'dial_stat',
            self.searchParams.callLogs.dial_stat.length,
            'cst_tms',
            dateHistogramIntervals
          ),
          limit: self.callLogsTable.options.itemsPerPage,
          offset: self.searchOffsetCallLogs,
          sort: self.searchSortCallLogs,
        }
      );

      try {
        AuthService.tokenRefresh();

        const response = await NotificationLogService.getJoinLogs(searchParams);

        // TODO: 後でちゃんと型定義する
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        response.items.forEach((item: any) => {
          const httpLogs = self._.get(item, '_joined_record.http', []);

          // データの追加に必要, ES上の要素名と表記を合わせるため除外
          // eslint-disable-next-line no-param-reassign, @typescript-eslint/camelcase
          item.$$$notify_status = this._.every(httpLogs, (httpLog) => {
            return httpLog.progress !== 'error';
          });
        });

        self.callLogsTable.expanded = [];
        self.callLogsTable.items = response.items;
        self.callLogsTable.serverItemsLength = response.filter_count;

        self.callLogsChart.disabledMonthly = self.callLogsChart.needsDisableMonthly;
        self.callLogsChart.disabledDaily = self.callLogsChart.needsDisableDaily;
        self.callLogsChart.disabledHourly = self.callLogsChart.needsDisableHourly;

        self.callLogsChart.aggregations = response.aggregations;
        self.callLogsChart.selectedDialStats = self.searchParams.callLogs.dial_stat;

        if (self.callLogsChart.interval === 'hour' && self.callLogsChart.disabledHourly) {
          self.callLogsChart.interval = 'day';
        }

        if (self.callLogsChart.interval === 'day' && self.callLogsChart.disabledDaily) {
          self.callLogsChart.interval = 'month';
        }

        if (self.callLogsChart.interval === 'month' && self.callLogsChart.disabledMonthly) {
          self.callLogsChart.interval = 'year';
        }

        self.debouncedUpdateChartCallLogs();
      } catch (error) {
        self.$$log.error(error);
        self.setErrorMessage({ text: error.message });
      }

      self.callLogsTable.loading = false;
    },

    // 最新の検索パラメーターで通知ログを検索する (発報単位)
    async searchCallLogsWithNewParams() {
      // デートタイムピッカーを開いた状態で検索ボタンを押すと値の変更反映前に検索が実行されるのを防ぐための措置
      await wait(100);

      this.$v.searchParams.callLogs.$touch();

      if (this.$v.searchParams.callLogs.$invalid) {
        this.$$log.debug(this.$v.searchParams.callLogs);
        return;
      }

      this.callLogsTable.gotSearchParams = this.getSearchParamsCallLogs();

      let from: dayjs.Dayjs;
      let to: dayjs.Dayjs;

      if (this.searchParams.callLogs.cst_tms.from === undefined) {
        // DBのデータ登録ミス以外でregisteredがundefinedになることはない
        from = this.registered || this.$$dayjs();
      } else {
        from = this.$$dayjs(this.searchParams.callLogs.cst_tms.from);
      }

      if (this.searchParams.callLogs.cst_tms.to === undefined) {
        to = this.$$dayjs();
      } else {
        to = this.$$dayjs(this.searchParams.callLogs.cst_tms.to);
      }

      this.callLogsChart.needsDisableMonthly = to.isAfter(
        from.clone().add(displayableMaxMonthsInMonthlyChart, 'months')
      );

      this.callLogsChart.needsDisableDaily = to.isAfter(
        from.clone().add(displayableMaxDaysInDailyChart, 'days')
      );

      this.callLogsChart.needsDisableHourly = to.isAfter(
        from.clone().add(displayableMaxDaysInHourlyChart, 'days')
      );

      this.callLogsTable.options.page = 1;
      this.debouncedSearchCallLogs();
    },

    // 通知ログを検索する (リクエスト単位)
    async searchRequestLogs() {
      const self = this;

      const dateHistogramIntervals: string[] = ['year'];

      self.requestLogsTable.loading = true;

      if (!self.requestLogsChart.needsDisableMonthly) {
        dateHistogramIntervals.push('month');
      }

      if (!self.requestLogsChart.needsDisableDaily) {
        dateHistogramIntervals.push('day');
      }

      if (!self.requestLogsChart.needsDisableHourly) {
        dateHistogramIntervals.push('hour');
      }

      const searchParams = self._.assignIn(
        {},
        self.requestLogsTable.gotSearchParams,
        staticSearchParams.requestLogs,
        {
          aggs: generateAggregateParams('http_status', 10, 'trn_tms', dateHistogramIntervals),
          limit: self.requestLogsTable.options.itemsPerPage,
          offset: self.searchOffsetRequestLogs,
          sort: self.searchSortRequestLogs,
        }
      );

      try {
        AuthService.tokenRefresh();

        const response = await NotificationLogService.getLogs(searchParams);

        // TODO: 後でちゃんと型定義する
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        response.items.forEach((item: any) => {
          // データの追加に必要, ES上の要素名と表記を合わせるため除外
          // eslint-disable-next-line no-param-reassign, @typescript-eslint/camelcase
          item.$$$trn_id = item.trn_id;

          try {
            // データの追加に必要, ES上の要素名と表記を合わせるため除外
            // eslint-disable-next-line no-param-reassign, @typescript-eslint/camelcase
            item.$$$formatted_req_body = JSON.stringify(JSON.parse(item.req_body), null, 2);
          } catch (error) {
            // ESにJSONとして解析できない不正データをわざと設定しない限り発生しない
            // エラーメッセージは表示せず、未整形の文字列を利用する

            self.$$log.error(error);

            // データの追加に必要, ES上の要素名と表記を合わせるため除外
            // eslint-disable-next-line no-param-reassign, @typescript-eslint/camelcase
            item.$$$formatted_req_body = item.req_body;
          }

          // データの追加に必要, ES上の要素名と表記を合わせるため除外
          /* eslint-disable no-param-reassign, @typescript-eslint/camelcase */
          item.$$$formatted_req_headers = JSON.stringify(item.req_headers, null, 2);
          item.$$$formatted_http_response = JSON.stringify(item.http_response, null, 2);
          /* eslint-enable no-param-reassign, @typescript-eslint/camelcase */
        });

        self.requestLogsTable.expanded = [];
        self.requestLogsTable.items = response.items;
        self.requestLogsTable.serverItemsLength = response.filter_count;

        self.requestLogsChart.disabledMonthly = self.requestLogsChart.needsDisableMonthly;
        self.requestLogsChart.disabledDaily = self.requestLogsChart.needsDisableDaily;
        self.requestLogsChart.disabledHourly = self.requestLogsChart.needsDisableHourly;

        self.requestLogsChart.aggregations = response.aggregations;
        self.requestLogsChart.selectedProgresses = self.searchParams.requestLogs.progress;

        if (self.requestLogsChart.interval === 'hour' && self.requestLogsChart.disabledHourly) {
          self.requestLogsChart.interval = 'day';
        }

        if (self.requestLogsChart.interval === 'day' && self.requestLogsChart.disabledDaily) {
          self.requestLogsChart.interval = 'month';
        }

        if (self.requestLogsChart.interval === 'month' && self.requestLogsChart.disabledMonthly) {
          self.requestLogsChart.interval = 'year';
        }

        self.debouncedUpdateChartRequestLogs();
      } catch (error) {
        self.$$log.error(error);
        self.setErrorMessage({ text: error.message });
      }

      self.requestLogsTable.loading = false;
    },

    // 最新の検索パラメーターで通知ログを検索する (リクエスト単位)
    async searchRequestLogsWithNewParams() {
      // デートタイムピッカーを開いた状態で検索ボタンを押すと値の変更反映前に検索が実行されるのを防ぐための措置
      await wait(100);

      this.$v.searchParams.requestLogs.$touch();

      if (this.$v.searchParams.requestLogs.$invalid) {
        this.$$log.debug(this.$v.searchParams.requestLogs);
        return;
      }

      this.requestLogsTable.gotSearchParams = this.getSearchParamsRequestLogs();

      let from: dayjs.Dayjs;
      let to: dayjs.Dayjs;

      if (this.searchParams.requestLogs.trn_tms.from === undefined) {
        // DBのデータ登録ミス以外でregisteredがundefinedになることはない
        from = this.registered || this.$$dayjs();
      } else {
        from = this.$$dayjs(this.searchParams.requestLogs.trn_tms.from);
      }

      if (this.searchParams.requestLogs.trn_tms.to === undefined) {
        to = this.$$dayjs();
      } else {
        to = this.$$dayjs(this.searchParams.requestLogs.trn_tms.to);
      }

      this.requestLogsChart.needsDisableMonthly = to.isAfter(
        from.clone().add(displayableMaxMonthsInMonthlyChart, 'months')
      );

      this.requestLogsChart.needsDisableDaily = to.isAfter(
        from.clone().add(displayableMaxDaysInDailyChart, 'days')
      );

      this.requestLogsChart.needsDisableHourly = to.isAfter(
        from.clone().add(displayableMaxDaysInHourlyChart, 'days')
      );

      this.requestLogsTable.options.page = 1;
      this.debouncedSearchRequestLogs();
    },

    // ES上の要素名のため除外
    // eslint-disable-next-line @typescript-eslint/camelcase
    showCallLogsTabAndSearchCallLogs(trn_id: string) {
      this.resetCallLogs();

      this.searchParams.callLogs.cst_tms.from = undefined;
      // ES上の要素名のため除外
      // eslint-disable-next-line @typescript-eslint/camelcase
      this.searchParams.callLogs.free_word = trn_id;

      this.selectedLogType = 'callLogs';

      this.searchCallLogsWithNewParams();
    },

    // Webhook詳細ダイアログを表示する
    // ES上の要素名のため除外
    // eslint-disable-next-line @typescript-eslint/camelcase
    async showDialogWebhook(message_id: string) {
      const self = this;

      if (self.loading.notifyResult) {
        return;
      }

      self.loading.notifyResult = true;

      try {
        const response = await NotificationLogService.getLogs({
          filters: [
            {
              bool: {
                should: [
                  {
                    match: {
                      // ES上の要素名のため除外
                      // eslint-disable-next-line @typescript-eslint/camelcase
                      message_id,
                    },
                  },
                  {
                    match: {
                      // ES上の要素名のため除外
                      // eslint-disable-next-line @typescript-eslint/camelcase
                      origin_message_id: message_id,
                    },
                  },
                ],
              },
            },
          ],
          limit: 1,
          // ES上の要素名のため除外
          // eslint-disable-next-line @typescript-eslint/camelcase
          sort: { proc_start_tms: -1 },
          sources: ['proc_start_tms', 'proc_end_tms', 'progress', 'req_data', 'res_result'],
          type: 'http',
        });

        const index = self._.findIndex(NOTIFY_PROGRESSES, ['value', response.items[0].progress]);
        let progress: string;

        if (index === -1) {
          progress = '';
        } else {
          progress = NOTIFY_PROGRESSES[index].name;
        }

        self.webhookDetails = {
          // ES上の要素名のため除外
          /* eslint-disable @typescript-eslint/camelcase */
          proc_end_tms: self.$$dayjsParseAndFormatToDateTimeString(response.items[0].proc_end_tms),
          proc_start_tms: self.$$dayjsParseAndFormatToDateTimeString(
            response.items[0].proc_start_tms
          ),
          progress,
          req_data: JSON.stringify(response.items[0].req_data, null, 2),
          res_result: JSON.stringify(response.items[0].res_result, null, 2),
          /* eslint-enable @typescript-eslint/camelcase */
        };

        self.showedDialog.webhook = true;
      } catch (error) {
        self.$$log.error(error);
        self.setErrorMessage({ text: error.message });
      }

      self.loading.notifyResult = false;
    },

    // グラフを更新する (発報単位)
    updateChartCallLogs() {
      const self = this;

      // vue-chartjsの型定義が複雑で上手く代入できないため妥協
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const datasets: any[] = [];
      const labels: string[] = [];

      let buckets: aggregationsBucketCallLogs[] | never[];
      let chartDisplayFormat: string;

      if (self.callLogsChart.interval === 'hour') {
        buckets = self._.get(self.callLogsChart.aggregations, 'by_hour.buckets', []);
        chartDisplayFormat = self.$$dayjsFormats.chartDisplayFormatDateTimeMonthDayHour;
      } else if (self.callLogsChart.interval === 'day') {
        buckets = self._.get(self.callLogsChart.aggregations, 'by_day.buckets', []);
        chartDisplayFormat = self.$$dayjsFormats.chartDisplayFormatDateMonthDay;
      } else if (self.callLogsChart.interval === 'month') {
        buckets = self._.get(self.callLogsChart.aggregations, 'by_month.buckets', []);
        chartDisplayFormat = self.$$dayjsFormats.chartDisplayFormatDateYearMonth;
      } else {
        buckets = self._.get(self.callLogsChart.aggregations, 'by_year.buckets', []);
        chartDisplayFormat = self.$$dayjsFormats.chartDisplayFormatDateYear;
      }

      const dialStats = self._.filter(DIAL_STATS, (dialStat) => {
        return self.callLogsChart.selectedDialStats.includes(dialStat.value);
      });

      // 通話状況別のデータセットを作成
      dialStats.forEach((dialStat) => {
        datasets.push({
          backgroundColor: dialStat.colorHex || self.$vuetify.theme.themes.light[dialStat.color],
          data: [],
          label: dialStat.name,
        });
      });

      // データセットにESから取得したデータを格納
      buckets.forEach((bucket) => {
        labels.push(self.$$dayjsParse(bucket.key_as_string).format(chartDisplayFormat));

        // 日付別のデータ
        const internalBuckets = bucket.by_dial_stat.buckets;

        dialStats.forEach((dialStat, index) => {
          const matched = self._.find(internalBuckets, ['key', dialStat.value]);

          if (matched === undefined) {
            datasets[index].data.push(0);
          } else {
            datasets[index].data.push(matched.doc_count);
          }
        });
      });

      self.callLogsChart.chartData = {
        datasets,
        labels,
      };
    },

    // グラフを更新する (リクエスト単位)
    updateChartRequestLogs() {
      const self = this;

      // vue-chartjsの型定義が複雑で上手く代入できないため妥協
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const datasets: any[] = [];
      const labels: string[] = [];

      let buckets: aggregationsBucketRequestLogs[] | never[];
      let chartDisplayFormat: string;

      if (self.requestLogsChart.interval === 'hour') {
        buckets = self._.get(self.requestLogsChart.aggregations, 'by_hour.buckets', []);
        chartDisplayFormat = self.$$dayjsFormats.chartDisplayFormatDateTimeMonthDayHour;
      } else if (self.requestLogsChart.interval === 'day') {
        buckets = self._.get(self.requestLogsChart.aggregations, 'by_day.buckets', []);
        chartDisplayFormat = self.$$dayjsFormats.chartDisplayFormatDateMonthDay;
      } else if (self.requestLogsChart.interval === 'month') {
        buckets = self._.get(self.requestLogsChart.aggregations, 'by_month.buckets', []);
        chartDisplayFormat = self.$$dayjsFormats.chartDisplayFormatDateYearMonth;
      } else {
        buckets = self._.get(self.requestLogsChart.aggregations, 'by_year.buckets', []);
        chartDisplayFormat = self.$$dayjsFormats.chartDisplayFormatDateYear;
      }

      // HTTPステータス別のデータセットを作成
      HTTP_STATUSES.forEach((httpStatus) => {
        datasets.push({
          backgroundColor:
            httpStatus.colorHex || self.$vuetify.theme.themes.light[httpStatus.color],
          data: [],
          label: httpStatus.value,
        });
      });

      // データセットにESから取得したデータを格納
      buckets.forEach((bucket) => {
        labels.push(self.$$dayjsParse(bucket.key_as_string).format(chartDisplayFormat));

        // 日付別のデータ
        const internalBuckets = bucket.by_http_status.buckets;

        HTTP_STATUSES.forEach((httpStatus, index) => {
          const matched = self._.find(internalBuckets, ['key', httpStatus.value]);

          if (matched === undefined) {
            datasets[index].data.push(0);
          } else {
            datasets[index].data.push(matched.doc_count);
          }
        });
      });

      self.requestLogsChart.chartData = {
        datasets,
        labels,
      };
    },
  },

  validations() {
    return {
      searchParams: {
        callLogs: {
          // ES上の要素名のため除外
          // eslint-disable-next-line @typescript-eslint/camelcase
          cst_tms: {
            validDateRange: this.$$validators.validDateRange(),
          },
          // ES上の要素名のため除外
          // eslint-disable-next-line @typescript-eslint/camelcase
          dial_stat: {
            required: helpers.withParams({ type: '_requiredSelect' }, required),
          },
          // ES上の要素名と表記を合わせるため除外
          // eslint-disable-next-line @typescript-eslint/camelcase
          free_word: {
            maxLength: maxLength(255),
          },
        },
        requestLogs: {
          // ES上の要素名と表記を合わせるため除外
          // eslint-disable-next-line @typescript-eslint/camelcase
          free_word: {
            maxLength: maxLength(255),
          },
          progress: {
            required: helpers.withParams({ type: '_requiredSelect' }, required),
          },
          // ES上の要素名のため除外
          // eslint-disable-next-line @typescript-eslint/camelcase
          trn_tms: {
            validDateRange: this.$$validators.validDateRange(),
          },
        },
      },
    };
  },
});
